// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-project-template-js": () => import("../src/templates/project-template.js" /* webpackChunkName: "component---src-templates-project-template-js" */),
  "component---src-templates-news-template-js": () => import("../src/templates/news-template.js" /* webpackChunkName: "component---src-templates-news-template-js" */),
  "component---src-templates-plans-template-js": () => import("../src/templates/plans-template.js" /* webpackChunkName: "component---src-templates-plans-template-js" */),
  "component---src-templates-overview-template-js": () => import("../src/templates/overview-template.js" /* webpackChunkName: "component---src-templates-overview-template-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-financieel-js": () => import("../src/pages/financieel.js" /* webpackChunkName: "component---src-pages-financieel-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nieuws-js": () => import("../src/pages/nieuws.js" /* webpackChunkName: "component---src-pages-nieuws-js" */),
  "component---src-pages-plannen-js": () => import("../src/pages/plannen.js" /* webpackChunkName: "component---src-pages-plannen-js" */),
  "component---src-pages-privacy-js": () => import("../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-projecten-js": () => import("../src/pages/projecten.js" /* webpackChunkName: "component---src-pages-projecten-js" */)
}

